
.to-dot-scanner {

    &__header {

        padding: 0;

        img {
            width: 100%;
            max-width: 400px;
        }
    }
}