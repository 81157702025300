@import '~@/scss/mixins';

.image-input {
    @include button('.image-input');
    position: relative;

    &__input {
        visibility: hidden;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__icon {

        &--file {
            background-image: url(~@images/icons/picture.svg);
        }

        &--camera {
            background-image: url(~@images/icons/camera.svg);
        }
    }
}