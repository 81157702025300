@import '~@/scss/mixins';

.pictures-step {

    .screen-content {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    &__thumbnails {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        margin-bottom: auto;
        padding-top: 15px;
    }

    &__thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            @include card;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}