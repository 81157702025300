@import '~@/scss/mixins';

.button {
    @include button('.button');

    &__icon {

        &--add {
            background-image: url(~@images/icons/add-round.svg);
        }

        &--scan {
            background-image: url(~@images/icons/scan.svg);
        }

        &--list {
            background-image: url(~@images/icons/list-stagger.svg);
        }

        &--next {
            background-image: url(~@images/icons/forward.svg);
        }

        &--back {
            background-image: url(~@images/icons/back.svg);
        }

        &--print {
            background-image: url(~@images/icons/print.svg);
        }

        &--cancel, &--close {
            background-image: url(~@images/icons/x.svg);
        }

        &--done, &--check {
            background-image: url(~@images/icons/check.svg);
        }

        &--google {
            background-image: url(~@images/icons/google.svg);
        }

        &--facebook {
            background-image: url(~@images/icons/facebook.svg);
        }
    }
}