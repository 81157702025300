
.to-dot-qr-code {

    position: relative;

    &__frame {
        background: url(~@images/frames/todot-frame-alt1.svg) no-repeat center center;
        background-size: 94%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    canvas, svg {
        width: 70%;
        height: 70%;
        object-fit: contain;
    }

    //&::after {
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    right: 0;
    //    bottom: 0;
    //    left: 0;
    //    margin: auto;
    //    width: 20%;
    //    height: 20%;
    //    background: white url(~@images/logo.png) no-repeat center center;
    //    background-size: calc(100% - 5px);
    //    border-radius: 50%;
    //}
}
