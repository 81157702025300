
.input {

    display: inline-flex;
    position: relative;
    padding-top: 10px;

    &__input {
        width: 100%;
    }

    &__label {
        position: absolute;
        top: 0;
        left: 15px;
        background-color: white;
        font-family: "Bebas Neue", sans-serif;
        font-size: 20px;
        padding: 0 5px 5px 5px;
    }
}