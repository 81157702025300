
.to-dot-card {

    display: grid;
    grid-template-areas: 'status summary thumbnail';
    grid-template-columns: 40px minmax(200px, 1fr) 40px;
    gap: 10px;
    //border: 1px solid black;
    //border-left: 12px solid black;
    //padding: 5px;
    //border-radius: 0 50px 50px 0;

    &--complete {
        border-color: #29A829;
        &::after {
            background-image: url(~@images/icons/check-green-circle.svg) !important;
        }
    }

    &::after {
        content: '';
        grid-area: status;
        background: no-repeat center center url(~@images/icons/todo.svg);
        background-size: 40px 40px;
        display: block;
        width: 40px;
        height: 40px;

    }

    &__thumbnail {
        grid-area: thumbnail;
        width: 40px;
        height: 40px;
        background-color: white;
        flex: 0 0 auto;
        border: 2px solid black;
        padding: 2px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__summary {
        grid-area: summary;
        display: flex;
        align-items: center;
        min-height: 40px;
        padding-left: 5px;
    }

    &__title {
        font-weight: 700;

        .to-dot-card--complete & {
            text-decoration: line-through;
        }
    }

    &__description {
        width: 20ch;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}