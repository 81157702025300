@mixin shadow-box {
    border: 4px solid black;
    box-shadow: 0 8px 0 -4px;
    margin-bottom: 4px;
}

@mixin card {
    border: 2px solid black;
    padding: 5px;
}

@mixin screen {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 100%;
}

@mixin button($prefix: '') {

    @include shadow-box;
    padding: 0;
    height: 50px;
    background-color: white;
    font-size: 20px;
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: none;

    &--icon-only {
        flex: 0 0 auto !important;
    }

    &__content {
        width: 100%;
        //max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__label {
        padding: 0 10px;
        order: 0;
        white-space: nowrap;
    }

    &__icon {
        $size: 36px;
        order: 1;
        width: $size;
        height: $size;
        background: no-repeat center center;
        background-size: $size $size;
        margin: 0 3px;

        #{$prefix}__label + & {
            margin: 0 5px 0 auto;
        }
    }

    &--disabled {

        pointer-events: none;
        color: #999999;
        border-color: currentColor;

        #{$prefix}__icon {
            opacity: .5;
        }

        * {
            pointer-events: none;
        }
    }

    //@media (max-width: 375px) {
    //    height: 45px;
    //    font-size: 16px;
    //}
}

@mixin markdown {

    /*
  1. Use a more-intuitive box-sizing model.
*/
    *, *::before, *::after {
        all: revert;
        font-family: inherit;
        box-sizing: border-box;
    }
    /*
      2. Remove default margin
    */
    * {
        margin: 0;
    }
    /*
      6. Improve media defaults
    */
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }
    /*
      8. Avoid text overflows
    */
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }
}