@import '~@/scss/mixins';

.to-dot-viewer {

    &__header {

    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        h2 {
            font-family: "Bebas Neue", sans-serif;
            font-size: 24px;
        }
    }

    &__title {
        font-weight: 700;
    }

    &__to-done,
    &__description {
        @include markdown;
    }

    &__created {
        font-size: 14px;
        display: grid;
        grid-template-columns: fit-content(100px) 1fr;
        column-gap: 10px;
        align-items: center;

        &-label {
            font-weight: 700;
            white-space: nowrap;
            text-transform: uppercase;
            color: #aaa;
            font-size: 12px;
        }
    }

    &__images {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 10px;
    }

    &__image {
        @include card;
        width: 100%;
        display: flex;

        img {
            width: 100%;
        }
    }
}