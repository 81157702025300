.to-dot-printer {

    &__printable-pages {
        display: none;
    }

    &__layouts {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-auto-rows: min-content;
        grid-gap: 10px;
    }

    &__layout {
        width: 100%;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

}


@media print {

    * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important;  /*Firefox*/
    }

    .to-dot-printer {

        display: none;

        &__printable-pages {
            display: initial;
        }

        $size: var(--labelSize);

        &__printable-page {

            width: 8.5in;
            height: 11in;
            display: grid;
            grid-auto-rows: $size;
            grid-template-columns: repeat(auto-fit, $size);
            column-gap: var(--colGap);
            row-gap: var(--rowGap);
            padding-top: var(--topPadding);
            padding-left: var(--leftPadding);
        }

        &__printable-label {
            //border: .4mm solid black;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $size;
            height: $size;
            padding: .025in;

            //&--round {
                border-radius: 100%;
                //padding: 20%;
            //}
        }

        /*
        &--24s {

            $size: 1.5in;
            .page {
                page: Test;
                display: grid;
                grid-auto-rows: $size;
                grid-template-columns: repeat(auto-fit, $size);
                column-gap: .3132in;
                row-gap: .2in;
                padding: .625in;
            }

            .marker {
                border: .5mm solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: $size;
                height: $size;
                padding: .1in;
            }
        }
        &--24r {

            $size: 1.5in;
            .page {
                display: grid;
                grid-auto-rows: 2in;
                grid-template-columns: repeat(auto-fit, $size);
                column-gap: .3132in;
                row-gap: .2in;
                padding: .625in;
            }

            .marker {
                border-radius: 50%;
                border: .5mm solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: $size;
                height: $size;
                padding: 20%;
            }
        }
        &--20s {

            .page {
                page: Test;
                display: grid;
                grid-auto-rows: 2in;
                grid-template-columns: repeat(auto-fit, 2in);
                column-gap: .625in;
                row-gap: calc(1.75in / 3);
                padding: .625in;
            }

            .marker {
                border: .5mm solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2in;
                height: 2in;
                padding: .1in;
            }
        }
        &--20r {
            .page {
                display: grid;
                grid-auto-rows: 2in;
                grid-template-columns: repeat(auto-fit, 2in);
                column-gap: .625in;
                row-gap: calc(1.75in / 3);
                padding: .625in;
            }

            .marker {
                border-radius: 50%;
                border: .5mm solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2in;
                height: 2in;
                padding: 20%;
            }
        }
        &--15s {
            .page {
                display: grid;
                grid-auto-rows: 1.5in;
                grid-template-columns: repeat(auto-fit, 1.5in);
                gap: .5in;
                padding: .75in .5in;
            }

            .marker {
                border: .5mm solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5in;
                height: 1.5in;
                padding: .1in;
            }
        }
        &--15r {
            .page {
                display: grid;
                grid-auto-rows: 1.5in;
                grid-template-columns: repeat(auto-fit, 1.5in);
                gap: .5in;
                padding: .75in .5in;
            }

            .marker {
                border-radius: 50%;
                border: .5mm solid black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5in;
                height: 1.5in;
                padding: .3in;
            }
        }
        */
    }
}
