@keyframes pulse-black {
    0% {
        //transform: scale(0.95);
        background-color: rgba(red, 0.5);
        box-shadow: 0 0 0 0 rgba(red, 0.5);
    }

    90% {
        //transform: scale(1);
        background-color: rgba(red, 0);
        box-shadow: 0 0 0 50px rgba(red, 0);
    }

    100% {
        //transform: scale(0.95);
        background-color: rgba(red, 0);
        box-shadow: 0 0 0 0 rgba(red, 0);
    }
}