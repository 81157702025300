.print-layout-preview {
    position: relative;
    display: grid;
    grid-template-columns: repeat(var(--cols), minmax(0, 1fr));
    grid-template-rows: repeat(var(--rows), minmax(0, 1fr));
    width: 100%;
    aspect-ratio: 8.5/11;
    padding: 10px;
    color: #999999;
    border: var(--borderWidth, 1px) solid currentColor;

    &--active {
        color: black;
        --borderWidth: 2px;
    }

    &__dot {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10%;

        &::after {
            content: '';
            display: block;
            width: 100%;
            aspect-ratio: 1/1;
            border: var(--borderWidth, 1px) solid currentColor;
        }

        &--round {
            padding: 5%;
            &::after {
                border-radius: 100%;
            }
        }
    }

    &__size {
        position: absolute;
        bottom: 10px;
        right: 10px;
        border: 2px solid black;
        font-family: "Bebas Neue", sans-serif;
        padding: 10px 15px;
        color: black;
        background-color: white;

    }
}