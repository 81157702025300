@import '~@/scss/mixins';

.my-to-dots {

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .to-dot-card + .to-dot-card {
        border-top: 2px solid black;
        padding-top: 20px;
    }
}