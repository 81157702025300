@import "src/scss/mixins";

.home {


    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 20px 40px !important;
    }

    &__actions {
        justify-content: flex-end;
    }

}