@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&;display=swap');@import "src/scss/mixins";
@import './reset';
@import "./animations";

* {
  box-sizing: border-box;
}
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  @include button;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
  border: 4px solid black;
  box-shadow: inset 0 4px 0 0 #c8c8c8;
  padding: 15px;
  background-color: white;
  outline: none;
  font-family: inherit;
  font-size: 1rem;
}

@page  {
  size: 8.5in 11in;
  margin: 0;
}
