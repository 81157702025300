@import '~@/scss/_mixins';

.sign-up {

    &__header {
        text-align: center;
        padding: 5px 0 15px;

        img {
            width: 100%;
            max-width: 400px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        grid-row-gap: 5px;
    }
}