@import '~@/scss/mixins';

.modal {

    --backgroundColor: white;

    color: black;
    background-color: var(--backgroundColor);
    overflow: hidden;
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);

    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: rgba(white, .98);

        &--alert {
            z-index: 999999;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 0;
        font-weight: 800;
        font-size: 1.2em;
        width: 100%;
        column-gap: 10px;

        &__title {
            display: flex;
            flex: 1 1 auto;
            flex-wrap: wrap;
        }

        &__close {
            display: inline-flex;
            width: 30px;
            height: 30px;
            background: url(~@images/icons/x.svg) no-repeat center center;
            background-size: 20px auto;
            cursor: pointer;
        }
    }

    &-content {
        padding: 15px 30px;
    }

}

.confirm-modal,
.alert-modal {

    --backgroundColor: white;

    color: black;
    background-color: var(--backgroundColor, white);
    overflow: hidden;
    width: fit-content;
    height: fit-content;
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);
    display: flex;
    flex-direction: column;
    @include shadow-box;

    &__header {
        background-color: var(--backgroundColor, white);
        padding: 20px 20px 10px;
        flex: 0 0 auto;
    }

    &__title {
        text-align: center;
        font-family: "Bebas Neue", sans-serif;
        font-size: 24px;
    }

    &__content {
        padding: 20px 40px;
        background-color: var(--backgroundColor, white);
        flex: 1 1 auto;
        overflow: auto;

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__footer {
        background-color: var(--backgroundColor, white);
        padding: 15px;
        display: flex;
        justify-content: center;
        flex: 0 0 auto;
        column-gap: 15px;

        .button {
            flex: 1 1 100%;
        }
    }
}
