@import '~@/scss/mixins';

.screen {

    @include screen;

    &-header {

        .keyboard-open & {
            display: none;
        }

        text-align: center;

        h1 {
            padding: 15px 0 5px;
            font-size: 32px;
            font-family: 'Bebas Neue', sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;

            &::before {
                content: '';
                display: block;
                background: url(~@images/logo/logo-hollow.svg) no-repeat center center;
                background-size: contain;
                width: 40px;
                height: 40px;
            }
        }

        h2 {
            padding: 0 15px;
        }

        img {
            width: 100%;
            max-width: 400px;
        }
    }

    &-content {
        flex: 1 1 auto;
        overflow: auto;
        padding: 0 20px;
    }

    &-actions {
        padding: 10px 20px 20px 20px;
        display: flex;
        margin-top: auto;
        gap: 10px;

        .image-input,
        .button {
            flex: 1 1 100%;
        }

        &--stacked {
            flex-direction: column;

            .image-input,
            .button {
                flex: 0 0 auto;
            }
        }

        .keyboard-open & {
            display: none;
        }

    }

    &-empty-state {
        display: flex;
        flex-direction: column;
        padding: 40px;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #999999;
        font-family: "Bebas Neue", sans-serif;
        font-size: 2rem;
        row-gap: 1em;
    }
}