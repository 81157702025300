@import '~@/scss/mixins';

.speech-capture-input {

    @include button('.speech-capture-input');
    display: inline-flex;
    width: 50px;
    height: 50px;
    background: white url('../../images/icons/mic.svg') no-repeat center center;
    background-size: 40px 40px;

    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 40px;
        row-gap: 1em;
        text-align: center;
        font-family: "Bebas Neue", sans-serif;
        font-size: 2em;

        &::before {
            content: '';
            width: 100px;
            height: 100px;
            background: white url('../../images/icons/mic.svg') no-repeat center center;
            background-size: 80px 80px;
            border-radius: 50%;
            transform: scale(1);
            animation: pulse-black 1.5s infinite;
        }
    }
}

