
.details-step {

    &__description {
        flex: 1 1 auto;
        position: relative;
        display: flex;

        .text-area {
            height: 100%;
            width: 100%;
        }
    }

    &__record-trigger {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
}