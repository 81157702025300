
.qr-input {

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;

    &__preview {

        width: 100%;
        max-width: 500px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        border: 4px solid black;
        box-shadow: 0 12px 0 -8px black;

        video {
            @media (orientation: landscape) {
                height: 100%;
                position: absolute;
                left: -100em;
                right: -100em;
                margin: 0 auto;
            }

            @media (orientation: portrait) {
                width: 100%;
                position: absolute;
                top: -100em;
                bottom: -100em;
                margin: auto 0;
            }
        }
    }

    &__actions {
        margin-top: auto;
        display: flex;
        width: 100%;
        row-gap: 5px;

        button {
            border: 4px solid black;
            padding: 10px 15px;
            background-color: white;
            box-shadow: 0 12px 0 -8px black;
            margin-bottom: 4px;
            flex: 1 0 auto;
        }
    }
}